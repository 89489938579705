.swiper-container {
    width: 100%;
   
  }

  .swiper {
    z-index: 0;
  }
  
  /* @media screen and (max-width: 960px) {
    .swiper-container {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .swiper-container {
      width: 100%;
    }
  }

  @media screen and (max-width: 961px) {
    .swiper-container {
      width: 100%;
    }
  } */


.swiper-pagination-bullet {
  background: #FF8278;
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #FF8278;
    width : 25px;
    border-radius: 20px;
  }

 

/* .swiper-wrapper {
    padding-bottom: 50px;
    
} */
/* svg {
    display: inline-block;
    vertical-align: middle;
    position: relative !important;
    
  } */


  .mapSvgContainer{
    /* border : 1px solid black;  */
    position : relative; 
    display: flex;
    justify-content: center !important;
    /* height : "500px !important" */
  }

  .mapSvgContainer svg{ 
    position: relative !important;
    margin: 0 !important;
    padding: 0  !important;
    /* width: 100%; */
    height: 700px !important;
    top : -130px !important;
    left : 0;
    
  }


  @media screen and (max-width : 1080px) {
    .mapSvgContainer svg{ 
      top : -10px !important;
     }
  }

  @media screen and (max-width : 600px) {
    .mapSvgContainer svg{ 
      top : -10px !important;
     }
  }

  

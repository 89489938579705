
/* html{
  font-size: 62.5%;
  
}

@media screen and (max-width: 1600px) {
  html {  
    font-size: 62.5%;
  }
}

@media screen and (max-width: 960px) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 37.5%;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */
   overflow: -moz-scrollbars-none;
 
  
 
}

body::-webkit-scrollbar{
  display: none;
}






code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* #error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
} */
ul{
    margin: 0;
    padding-inline-start: 0;
}
.navItems-ul {
    display: flex;
    /* align-items: center; */
    gap : 15px;
    
    
}
 
.navItems-li {
    list-style: none;
    text-transform: uppercase;
    cursor: pointer;
    height: 80px;
    /* background-color: yellow; */
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 10px;

}

.navItems-li a{
    text-decoration: none;
    color : #DC002D;
}

.li-box {
    display: flex;
    align-items: center;
    gap : 8px;
    
}

.li-box-text{
    color : #DC002D;

}


.arrowDownIcon {
    width : 0px;
    height : 0px;
    border-left : 5px solid transparent;
    border-right : 5px solid transparent;
    border-top : 5px solid #DC002D;
    transition : transform 0.5s ease-in-out;
}


.navItems-li:hover .arrowDownIcon {
    transform: rotate(180deg);
    border-top-color: #1C1C1C; 
    color : #1C1C1C;
  }

 .navItems-li:hover .navItem-a {
    color: #1C1C1C;
 
 } 
 .navItems-li:hover .li-box .li-box-text {
    color: #1C1C1C;
 
 } 


/* when the mode is Dark */
.liDark .navItem-a {
    color : #ffffff
}

.liDark .li-box-text{
    color : #ffffff

}

.liDark:hover .navItem-a{
    color : #FF8278;
}

.liDark:hover .li-box .li-box-text{
    color : #FF8278;
}



.liDark .arrowDownIcon{
    border-top-color: #ffffff; 
}

 .liDark:hover .arrowDownIcon{
    transform: rotate(180deg);
    border-top-color:  #FF8278;
 }



 /* css for menu items */

 .menu {
    display: block;
    width: max-content;
    position: absolute;
    min-width: 200px;
    top : 80px;
    
 }
 .menu-ul {
    background-color: #1C1C1C;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
    gap : 15px;
    padding : 15px 30px
 }

 .menu-li {
    list-style: none;
 }

 .menu-li a {
    text-decoration: none;
    text-transform: none;
    color : #ffffff;
 }

 .menu-li a:hover {
    color : #FF8278;
 }

 .menuLiItem {
    display: flex;
    align-items: center;
    gap : 15px;
 }


 @media screen and (min-width: 961px) and (max-width: 1312px) {
    /* Your CSS styles for viewport width between 961px and 1280px go here */
    .navItems-ul {
      gap : 8px;
          }
}
